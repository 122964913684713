import { daEventCenter } from 'public/src/services/eventCenter/index'

export const formatDesc = (type, langText) => {
  if (type == 'freeShipping') {
    return langText.SHEIN_KEY_PWA_27184
  }
  if (type == 'promotion') {
    return langText.SHEIN_KEY_PWA_27185
  }
  if (type == 'newUser') {
    return langText.SHEIN_KEY_PWA_27191
  }
  if (type == 'coupons') {
    return langText.SHEIN_KEY_PWA_27190
  }
}

export const formatClickValue = (from) => {
  if (from == 'close') {
    return 'click_login_register_retain_pop_close'
  }
  if (from == 'leave') {
    return 'click_login_register_retain_pop_leave'
  }
  if (from == 'back') {
    return 'click_login_register_retain_pop_back'
  }
}

export const exposeScenesabt = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_scenesabt',
      scenes: 'loginRegisterRetain',
      pop_type: type,
    },
  })
}

export const clickRetainTracking = (type, from) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: formatClickValue(from),
      pop_type: type,
    },
  })
}

export const exposeRetainTracking = (type) => {
  daEventCenter.triggerNotice({
    daId: '2-8-112',
    extraData: {
      name: 'expose_login_register_retain_pop',
      pop_type: type,
    },
  })
}

<template>
  <svg
    width="285"
    height="90"
    viewBox="0 0 285 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="285"
      height="79"
      fill="url(#paint0_linear_1418_19807)"
    />
    <g
      style="mix-blend-mode:soft-light"
      opacity="0.4"
    >
      <mask
        id="mask0_1418_19807"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="285"
        height="69"
      >
        <path
          d="M285 0.5H0V68.5H285V0.5Z"
          fill="url(#paint1_linear_1418_19807)"
        />
      </mask>
      <g mask="url(#mask0_1418_19807)">
        <g
          style="mix-blend-mode:soft-light"
          opacity="0.35"
        >
          <path
            d="M142.5 66.0923L0.0397949 17.4244V0.5H33.6398L142.5 66.0923Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 66.0923L83.8101 0.5H117.42L142.5 66.0923Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 66.0923L167.59 0.5H201.19L142.5 66.0923Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 17.4244L142.5 66.0923L251.36 0.5H284.96V17.4244Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 66.0922L0.0397949 55.5094V38.585L142.5 66.0922Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 55.5094L142.5 66.0922L284.96 38.585V55.5094Z"
            fill="#F5FCFB"
          />
          <path
            d="M142.5 66.0923L0.0397949 93.5945V76.6701L142.5 66.0923Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 93.5945L142.5 66.0923L284.96 76.6701V93.5945Z"
            fill="#F5FCFB"
          />
          <path
            d="M33.6398 131.68H0.0397949V114.755L142.5 66.0923L33.6398 131.68Z"
            fill="#F5FCFB"
          />
          <path
            d="M117.42 131.68H83.8101L142.5 66.0923L117.42 131.68Z"
            fill="#F5FCFB"
          />
          <path
            d="M201.19 131.68H167.59L142.5 66.0923L201.19 131.68Z"
            fill="#F5FCFB"
          />
          <path
            d="M284.96 131.68H251.36L142.5 66.0923L284.96 114.755V131.68Z"
            fill="#F5FCFB"
          />
        </g>
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1418_19807"
        x1="142.5"
        y1="0"
        x2="142.5"
        y2="79"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB69D" />
        <stop
          offset="1"
          stop-color="white"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1418_19807"
        x1="142.5"
        y1="97.3788"
        x2="142.5"
        y2="0.94625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F5FCFB" />
        <stop
          offset="0.22"
          stop-color="#83BCA5"
        />
        <stop
          offset="1"
          stop-color="#198055"
        />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'NewRightBg'
}
</script>
